.table-container {
  width: 100%;
  margin: 0 auto;
  padding: 32px 20px 0px 20px;

  @media (max-width: 776px) {
    padding: 0 20px 0 20px;
  }

  table {
    border-collapse: collapse;
    box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);

    @media (max-width: 776px) {
      font-size: 12px;
    }

    th {
      text-align: left;
      padding: 12px 15px;
      font-family: var(--font-primary);
      background-color: var(--color-light-gray) !important;
    }

    td {
      padding: 12px 15px;
      border-bottom: 1px solid var(--color-light-gray);
      font-family: var(--font-primary);
      @media (max-width: 776px) {
        background-color: var(--color-primary);
      }
    }
  }
}
