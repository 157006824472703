.message-modal {
  z-index: 10001 !important;
  font-family: var(--font-primary);
  .message-modal-error-description {
    display: flex;
    font-size: 20px;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
  }
}

.modal .ant-modal-content {
  font-family: var(--font-primary) !important;
  border-radius: 30px;
}

.modal-body {
  padding-top: 15px;

  .form-item-label {
    font-family: var(--font-primary);

    &.date-picker {
      width: 100%;
      border-radius: 24px;
      height: 36px;
      border-color: var(--color-highlight);
    }
  }
  .input {
    font-family: var(--font-primary);
    border-radius: 24px;
    height: 36px;
    border-color: var(--color-highlight);

    &:hover {
      border-color: var(--color-highlight);
    }
  }

  .ant-select-selector {
    border-radius: 24px;
    font-family: var(--font-primary);
    height: 36px !important;
    border-color: var(--color-highlight) !important;

    .ant-select-selection-item {
      padding-top: 2px;
    }
  }

  .text-area {
    border-radius: 24px;
    font-family: var(--font-primary);
    border-color: var(--color-highlight);
    min-height: 36px;
    padding-top: 6px;
    color: var(--font-color);
    &:hover {
      border-color: var(--color-highlight);
    }
  }

  .ant-input-disabled {
    color: var(--font-color);
  }
}
