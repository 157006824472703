.header-main {
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 80px;
  display: grid;
  align-items: center;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.08);

  .header-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 776px) {
      font-size: 14px;
    }

    .logo-container {
      margin-left: 35px;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: 776px) {
        margin-left: 15px;
      }

      img {
        width: 50px;
      }

      .language-selector {
        margin-left: 50px;
        cursor: pointer;
        color: var(--font-color);
        font-weight: 700;
        margin-right: 10px;
        @media (max-width: 776px) {
          margin-left: 20px;
        }
      }
    }

    .menu-container {
      text-align: center;
      display: flex;
      flex-direction: row;
      .button.menu-button {
        margin-right: 10px;
        @media (max-width: 776px) {
          font-size: 16px;
          font-weight: 700;
          margin-right: 5px;
          height: 24px;
          display: flex;
          align-items: center;
          padding: 10px;
        }
        &.inactive {
          background-color: transparent;
          color: var(--font-color);
          &:hover {
            background-color: var(--color-highlight);
            color: var(--color-primary);
          }
        }
      }
    }

    .text-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 35px;
      right: 10px;
      line-height: 24px;
      color: var(--font-color);
      font-family: var(--font-primary);
      .auth-user-name {
        margin-left: 6px;
        cursor: pointer;
        font-weight: 700;
        .auth-user-profile-link {
          color: var(--font-color);
          text-decoration: none;
        }
      }
      .logout-button {
        cursor: pointer;
        color: var(--font-color);
        margin-left: 10px;
      }
    }
  }
}
