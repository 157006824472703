.subscriptions-page {

  width: calc(100% - 20px - 20px);

  .export-button {
    width: fit-content;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    border: 1px solid transparent;
    background-color: var(--color-light-green);
    font-size: 16px;
    float: right;
    margin-right: 16px;
    cursor: pointer;
    color: var(--color-primary);
    font-weight: 600;
    &:hover {
      background-color: var(--color-green-hover);
    }
  }

  .searchbar-container {
    width: calc(100% - 20px - 20px);
    margin-left: 20px;
  }
}
.ant-table-filter-dropdown-btns {
  .ant-btn {
    border-radius: 20px;
    background-color: var(--color-green-main);
    font-family: var(--font-primary);
    &:hover {
      background-color: var(--color-green-hover) !important;
    }
  }
  .ant-btn-link {
    color: var(--color-primary);
    &:hover {
      background-color: var(--color-green-hover) !important;
      color: var(--color-primary) !important;
    }
  }
}
