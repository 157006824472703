.searchbar {
  display: flex;
  flex-direction: row;
  border: none;
  outline: none;
  height: 48px;
  font-family: var(--font-primary);
  background-color: var(--color-primary);
  border-radius: 24px;
  padding-left: 21px;
  margin-bottom: 15px;
  box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);

  @media (max-width: 776px) {
    height: 36px;
    font-size: 12px;
  }

  .suffix-icon {
    font-size: 18px;
    padding-right: 10px;

    @media (max-width: 776px) {
      font-size: 12px;
      padding-right: 5px;
    }
  }
}
