.login-page {
  background-color: #a8ccab;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .logo {
    margin-bottom: 40px;
  }

  .input-container {
    width: 400px;
    .input {
      width: 100%;
      height: 48px;
      border: none;
      border-radius: 24px;
      fill-opacity: 1;
      text-decoration: none;
      background-color: var(--color-primary);
      margin-bottom: 16px;
      font-family: var(--font-primary);
      &:hover {
        cursor: default;
      }
    }
  }

  .forgot-password-link {
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    font-family: var(--font-primary);
    &:hover {
      color: var(--color-highlight);
    }
  }
}
