.layout--default {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: var(--color-gray-shader);
  height: 100vh;
  overflow: auto;

  .page {
    width: var(--page-width);
    align-items: center;
    position: relative;
    padding-top: 100px;

    @media (max-width: 1120px) {
      width: 100%;
    }
  }
}
